import * as React from "react";
import Layout from "../components/layout";
import Seo from "../components/seo";

const TermsPage = () => (
    <Layout>
        <Seo title={'Security Notice | Nemo Personal Finance'} description={'View the terms and conditions of our secured loans, interest, payments and loan agreements. For more information visit Nemo Personal Finance online today'} lang={'en-gb'} />
        <section className="oneCol blue cfx">
            <div className="bdr">
                <div className="bdrTop"></div>
                <div className="bdrMid cfx">
                    <div className="headingArea cfx">
                        <div className="bubble blue cfx cp">
                            <div className="inr">
                                <h1>Security Notice</h1>
                            </div>
                            <div className="bot"></div>
                        </div>
                        <div className="intro">
                            <p className="leading">SECURITY NOTICE - PLEASE READ THIS NOTICE CAREFULLY</p>
                        </div>
                    </div>
                    <p>
                        <strong>How do I know that Nemo’s website is secure if I am submitting my personal
                            information? <br /></strong>There are several pages on the Nemo website which ask you for
                        personal information. You can make sure these pages are secure by clicking on the pad lock icon
                        which appears in the address/status bar. This locked padlock shows that Nemo uses encryption to
                        protect the personal details that you enter. You can double click the lock to display Nemo’s
                        Security Certificate. The ‘Issued to’ name should match <a
                        href="http://www.nemo-loans.co.uk/">www.nemo-loans.co.uk</a>.</p>
                    <p>
                        <strong>What should I do if I think I'm on a fake website?</strong> <br />Fraudsters may set up
                        spoof websites which are made to look like the real thing to enable them to obtain your personal
                        information. In order to avoid this type of scam, you should always type in website addresses
                        carefully before entering any personal information.</p>
                    <p>
                        <strong>How will Nemo contact me? <br /></strong>We will use your contact information to send you
                        important information via letters, e-mails, text messages, or otherwise to telephone you about
                        your account. <br />We may also contact you via the above methods with optional account
                        information, such as reminders to pay ahead of any date payment is due. If you would like to
                        change the method for receiving optional account information, or if you would like to add or
                        remove this service, just contact Customer Services. Please see our&nbsp;<a
                            href="/contact-us">Contact Us</a> page for details how to contact us.</p>
                    <p>
                        <strong>Can I communicate with Nemo via e-mail?<br /></strong>If you do choose to send information
                        by e-mail you do so at your own risk.&nbsp; The majority of e-mail providers have adopted
                        appropriate security controls to protect your information but we cannot guarantee the security
                        of your specific provider.&nbsp; <br /><br />We will never ask you to send financial or sensitive
                        information via email and will only ever ask you to post or fax any documents back to us at our
                        head office. We will only send you e-mails if you ask us to, or if the only method we have for
                        contacting you with important information is via e-mail. For your protection, any e-mails we do
                        send to you will contain censored or obscured information. <br /><br />If you choose to send us an
                            e-mail, we will reply to you by e-mail after 24 hours have elapsed. If you are not
                            comfortable with the security of your provider and do not wish us to reply to you by e-mail,
                            please contact us on 029 2020 7053 or 0800 1382935 within 24 hours.&nbsp; <br /><br />Due to
                                Nemo’s commitment to the security of your personal information, if your e-mail is from
                                an e-mail address that we do not recognise for you, one of our agents will contact you
                                by telephone on the numbers we hold to ensure the e-mail has come from you and to update
                                your details with us.</p>
                    <p>
                        <strong>What should I do if I think I have received a scam or ‘phishing’ e-mail?</strong> <br />If
                        you think you have received a scam e-mail, don’t click on any links or pictures and don’t open
                        any attachments. Nemo will never send you an e-mail asking you for personal information. Such
                        e-mails are referred to as ‘phishing’ e-mails, and are named as such because fraudsters are
                        attempting to ‘fish’ for your personal security details. <br /><br />If you receive an e-mail asking
                            you to reply with your personal details please forward it to <a
                                href="mailto:phishing@nemoloans.co.uk">phishing@nemoloans.co.uk</a> in order to help us
                            combat online fraud. Nemo does not guarantee a response to every e-mail but you can be
                            assured we will investigate every report received.<br /></p>
                </div>
                <div className="bdrBot"></div>
            </div>
        </section>
    </Layout>
)

export default TermsPage;